import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Performance from './Performance.vue';
import Funcionalidades from './Funcionalidades.vue';
import Treinamentos from './Treinamentos.vue';
import store, { userToolStore, dashboardStore, mainStore, userActionStore } from '@/store';
import NovidadesModule from '@/store/modules/novidades';
import _ from 'lodash';
import { getModule } from 'vuex-module-decorators';
import ShowDialog from '@/components/ShowDialog.vue';
import MarketingPopupComponent from '@/components/MarketingPopupComponent.vue';
let NewDashboard = class NewDashboard extends Vue {
    constructor() {
        super(...arguments);
        this.fixedTools = [
            3,
            16,
            29,
            37,
            64,
            74,
            76,
        ];
        this.performances = [];
        this.trainings = [];
        this.currentNovidade = {};
        this.isEditing = false;
        this.isLoading = false;
        this.addDialog = false;
        this.newNovidade = { title: '', description: '', is_new: false };
    }
    get tools() {
        return userToolStore.userTools.filter((t) => this.fixedTools.includes(t.tool_id));
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get isAdmin() {
        return this.userProfile && this.userProfile.is_superuser;
    }
    get novidadesModule() {
        return getModule(NovidadesModule, store);
    }
    get novidades() {
        return this.novidadesModule.novidades;
    }
    get shouldShowNovidadesTreinamento() {
        return !userActionStore.visualizarDashboardPerformance;
    }
    openAddDialog(show = true) {
        const refs = this.$refs.showCreateNovidade;
        refs.dialog = show;
    }
    async addNovidade() {
        await this.novidadesModule.createNovidade(this.newNovidade);
        this.newNovidade = { title: '', description: '', is_new: false };
        this.openAddDialog(false);
    }
    async handleUpdateNovidade(updatedNovidade) {
        await this.novidadesModule.updateNovidade(updatedNovidade);
    }
    async handleDeleteNovidade(id) {
        await this.novidadesModule.deleteNovidade(id);
    }
    getNextPerformanceUpdate(horario) {
        if (horario) {
            // @ts-ignore
            return this.formatDateTime(new Date(new Date(horario).getTime() + 30 * 60000));
        }
        // @ts-ignore
        return this.formatDateTime(new Date(new Date().getTime() + 30 * 60000));
    }
    getCurrentPerformanceUpdate(horario) {
        if (horario) {
            // @ts-ignore
            return this.formatDateTime(new Date(new Date(horario).getTime()));
        }
        // @ts-ignore
        return this.formatDateTime(new Date());
    }
    getPath(tool) {
        if (tool.group != '') {
            tool.group = tool.group.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
        }
        let path = '/main/' +
            tool.group +
            tool.name.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
        if (path.includes('portal')) {
            path = path.replace('portal', 'portal/');
        }
        return this.$router.push(path);
    }
    verifyLocalStorage(item) {
        let retorno = true;
        if (!item || item == 'undefined') {
            return false;
        }
        const listaTrainings = JSON.parse(item);
        if (listaTrainings.length <= 0) {
            return false;
        }
        _.forEach(listaTrainings, (el) => {
            if (!el.data_coleta) {
                retorno = false;
            }
            // verificar se a ultima data de obtenção dos treinamentos já se passou 15 minutos, pois os avatares tem tempo de expiração
            const data_coleta = new Date(new Date(el.data_coleta).getTime() + (14 * 60000));
            const limite_bucket = new Date(new Date().getTime() + (3 * 60 * 60000));
            if (data_coleta < limite_bucket) {
                retorno = false;
            }
        });
        return retorno;
    }
    async mounted() {
        this.isLoading = true;
        if (this.isAdmin) {
            await this.novidadesModule.getNovidades();
            return;
        }
        await this.novidadesModule.getNovidades();
        this.performances = await dashboardStore.getDashboadData();
        const performanceIdAlunoRematricula = this.performances.find(performance => performance.title === 'ALUNOS PARA REMATRICULAR');
        const performanceIdRematriculadoSemestre = this.performances.find(performance => performance.title === 'REMATRICULADOS DO SEMESTRE');
        if (performanceIdAlunoRematricula && performanceIdRematriculadoSemestre) {
            performanceIdAlunoRematricula.number = `${performanceIdAlunoRematricula.number}/${performanceIdRematriculadoSemestre.number}`;
        }
        const keyData = `key-treinamentos-${this.userProfile.company.id}`;
        const today = new Date();
        const key = `${keyData}-${today.toISOString().split('T')[0]}-${today.getHours()}`;
        const item = localStorage.getItem(key);
        let carregarTraings = true;
        if (this.verifyLocalStorage(item)) {
            this.trainings = JSON.parse(item);
            carregarTraings = false;
        }
        if (carregarTraings) {
            for (let i = 0; i < localStorage.length; i++) {
                const keyStorage = localStorage.key(i);
                if (keyStorage.startsWith(keyData)) {
                    localStorage.removeItem(keyStorage);
                    i--; // Ajustar o índice devido à remoção de um item
                }
            }
            this.trainings = await dashboardStore.getDashboardMainPageTreinamentos();
            localStorage.setItem(key, JSON.stringify(this.trainings));
        }
        this.isLoading = false;
    }
};
NewDashboard = __decorate([
    Component({
        components: {
            Performance,
            Funcionalidades,
            Treinamentos,
            ShowDialog,
            MarketingPopupComponent,
        },
    })
], NewDashboard);
export default NewDashboard;
