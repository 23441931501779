var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v(" Recebimentos por vencimento ")])]),_c('v-card-subtitle',[_vm._v(" Criar Registro ")]),(_vm.entityObject)?_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Nome","vid":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Descrição","error-messages":errors},model:{value:(_vm.entityObject.observacao),callback:function ($$v) {_vm.$set(_vm.entityObject, "observacao", $$v)},expression:"entityObject.observacao"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Categoria","vid":"categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Categoria","items":_vm.financeiroCategoriasReceber,"item-text":"nome","item-value":"id","error-messages":errors},model:{value:(_vm.entityObject.financeiro_categoria_id),callback:function ($$v) {_vm.$set(_vm.entityObject, "financeiro_categoria_id", $$v)},expression:"entityObject.financeiro_categoria_id"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('DateFieldComponent',{attrs:{"propTitle":"Data de Emissão"},model:{value:(_vm.entityObject.data_emissao),callback:function ($$v) {_vm.$set(_vm.entityObject, "data_emissao", $$v)},expression:"entityObject.data_emissao"}})],1),_c('v-col',[_c('DateFieldComponent',{attrs:{"propTitle":"Data de Vencimento"},model:{value:(_vm.entityFinanceiroParcela.vencimento),callback:function ($$v) {_vm.$set(_vm.entityFinanceiroParcela, "vencimento", $$v)},expression:"entityFinanceiroParcela.vencimento"}})],1),_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Valor","vid":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"autocomplete":"new","name":"new","label":"Valor","error-messages":errors},model:{value:(_vm.entityObject.valor_integral),callback:function ($$v) {_vm.$set(_vm.entityObject, "valor_integral", $$v)},expression:"entityObject.valor_integral"}})]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.entityObject)?_c('v-card-actions',[_c('SaveButton',{ref:"saveButton",attrs:{"propShowVoltar":false},on:{"submit":_vm.submit}})],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }