import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { dashboardStore, alunoStore, reposicaoStore } from '@/store';
import _ from 'lodash';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ShowDialog from '@/components/ShowDialog.vue';
import PrimeiraEtapaPopup from './PrimeiraEtapaPopup.vue';
import AlunaListReposicao from './AlunaListReposicao.vue';
let Reposicao = class Reposicao extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.search = '';
        this.classRoom = 'Todos';
        this.nameStudent = 'Todos';
        this.selectedTipoDados = '["Faltas consecutivas"]';
        this.firstStepObject = {};
        this.showMarcarReposicao = false;
        this.alunoStatusOptions = [
            'Vigente',
            'Trancado',
            'Matriculado',
            'Evadido',
            'Desistente',
            'Finalizado',
            'Sem contrato',
            'Pendente Rematrícula',
            'Rematriculado',
        ];
        this.filtrosDeFaltasConsecutivas = [
            '1 a 4 faltas consecutivas',
            '5 a 8 faltas consecutivas',
            'Mais de 8 faltas consecutivas',
        ];
        this.filtrosDeFaltasOptions = [
            ...this.filtrosDeFaltasConsecutivas,
            'Aluno sem reposição',
        ];
        this.propertyToShowDetails = [
            ...this.filtrosDeFaltasOptions.filter((item) => item !== 'Aluno sem reposição'),
            ...this.alunoStatusOptions,
            ...this.diasDaSemana(),
        ];
        this.reposicaoHeaders = [
            { text: 'Aluno', value: 'aluno_nome' },
            // { text: 'Status', value: 'contrato_status' },
            { text: 'Ações', value: 'actions' },
            { text: 'Turma', value: 'livro_nome' },
            { text: 'Horários', value: 'horarios' },
            {
                text: 'Qtd de faltas',
                value: 'quantidade_faltas',
            },
            {
                text: 'Qtd de faltas consecutivas',
                value: 'quantidade_faltas_consecutivas',
            },
            { text: 'Última aula presente', value: 'ultima_atividade_presente' },
            {
                text: 'Qtd de dias que não vem na aula',
                value: 'quantidade_dias_nao_comparece_aula',
            },
            { text: 'Última reposição concluída', value: 'ultima_reposicao_concluida' },
            {
                text: 'Qtd homework em atraso',
                value: 'quantidade_homework_em_atraso',
            },
            {
                text: 'Qtd revisão em atraso',
                value: 'quantidade_revisao_atraso',
            },
            { text: 'Contatos', value: 'ultimo_contato' },
        ];
        this.items = [];
        this.selectedAluno = null;
        this.panelOpen = false;
        this.aluno = null;
    }
    get diaSemanaQuantidadeAlunos() {
        const obj = {
            'Domingo': 0,
            'Segunda-feira': 0,
            'Terça-feira': 0,
            'Quarta-feira': 0,
            'Quinta-feira': 0,
            'Sexta-feira': 0,
            'Sábado': 0,
        };
        _.forEach(this.dashboardReposicao, (item, index) => {
            // quantidade de alunos por dia da semana
            const diasSemana = _(item.cronograma_horario)
                // @ts-ignore
                .groupBy((i) => i.dia_semana)
                .value();
            const filtroDias = Object.keys(diasSemana);
            if (this.filtroStatus.includes(item.contrato_status)) {
                if (this.selectedTipoDados == `["Faltas sem reposição"]`) {
                    filtroDias.forEach((i) => {
                        obj[i]++;
                    });
                }
                else {
                    if (item.quantidade_faltas_consecutivas >= 1 &&
                        item.quantidade_faltas_consecutivas <= 4 &&
                        this.de1a4) {
                        filtroDias.forEach((i) => {
                            obj[i]++;
                        });
                    }
                    else if (item.quantidade_faltas_consecutivas >= 5 &&
                        item.quantidade_faltas_consecutivas <= 8 &&
                        this.de5a8) {
                        filtroDias.forEach((i) => {
                            obj[i]++;
                        });
                    }
                    else if (item.quantidade_faltas_consecutivas > 8 && this.mais8) {
                        filtroDias.forEach((i) => {
                            obj[i]++;
                        });
                    }
                }
            }
        });
        return obj;
    }
    filtrosIncluemConsecutivo() {
        for (const val of this.propertyToShowDetails) {
            if (this.filtrosDeFaltasConsecutivas.includes(val)) {
                return true;
            }
        }
        return false;
    }
    onChangePropertyToShowDetails(val, oldVal) {
        if (!oldVal.includes(this.filtrosDeFaltasOptions[3]) &&
            val.includes(this.filtrosDeFaltasOptions[3])) {
            this.propertyToShowDetails = this.propertyToShowDetails.filter((item) => {
                return !this.filtrosDeFaltasConsecutivas.includes(item);
            });
        }
        else if (oldVal.includes('Aluno sem reposição') &&
            this.filtrosIncluemConsecutivo()) {
            this.propertyToShowDetails = _.filter(this.propertyToShowDetails, (item) => {
                return item !== this.filtrosDeFaltasOptions[3];
            });
        }
    }
    get aluno_1_a_4_faltas() {
        return _.filter(
        // @ts-ignore
        this.dashboardReposicao, 
        // @ts-ignore
        (item) => {
            // const ultimaAtividadeTime = new Date(
            //   // @ts-ignore
            //   this.BRToDate(item.ultima_atividade_presente)
            // ).getTime();
            return (item.quantidade_faltas_consecutivas <= 4 &&
                item.quantidade_faltas_consecutivas >= 1);
        }).length;
    }
    get aluno_5_a_8_faltas() {
        return _.filter(
        // @ts-ignore
        this.dashboardReposicao, (item) => {
            // const ultimaAtividadeTime = new Date(
            //   // @ts-ignore
            //   this.BRToDate(item.ultima_atividade_presente)
            // ).getTime();
            return (item.quantidade_faltas_consecutivas >= 5 &&
                item.quantidade_faltas_consecutivas <= 8);
        }).length;
    }
    get aluno_mais_8_faltas() {
        return _.filter(
        // @ts-ignore
        this.dashboardReposicao, 
        // @ts-ignore
        (item) => {
            // const ultimaAtividadeTime = new Date(
            //   // @ts-ignore
            //   this.BRToDate(item.ultima_atividade_presente)
            // ).getTime();
            // @ts-ignore
            return item.quantidade_faltas_consecutivas > 8;
        }).length;
    }
    get dashboardReposicao() {
        return dashboardStore.dashboardReposicao;
    }
    get filtroStatus() {
        const arr = [];
        if (this.propertyToShowDetails.includes('Vigente')) {
            arr.push(1);
        }
        if (this.propertyToShowDetails.includes('Trancado')) {
            arr.push(3);
        }
        if (this.propertyToShowDetails.includes('Removido')) {
            arr.push(9);
        }
        if (this.propertyToShowDetails.includes('Matriculado')) {
            arr.push(10);
        }
        if (this.propertyToShowDetails.includes('Evadido')) {
            arr.push(5);
        }
        if (this.propertyToShowDetails.includes('Desistente')) {
            arr.push(6);
        }
        if (this.propertyToShowDetails.includes('Finalizado')) {
            arr.push(11);
        }
        if (this.propertyToShowDetails.includes('Pendente Rematrícula')) {
            arr.push(11);
        }
        if (this.propertyToShowDetails.includes('Rematriculado')) {
            arr.push(12);
        }
        if (this.propertyToShowDetails.includes('Sem contrato')) {
            arr.push(null);
        }
        return arr;
    }
    get de1a4() {
        return this.propertyToShowDetails.includes(this.filtrosDeFaltasConsecutivas[0]);
    }
    get de5a8() {
        return this.propertyToShowDetails.includes(this.filtrosDeFaltasConsecutivas[1]);
    }
    get mais8() {
        return this.propertyToShowDetails.includes(this.filtrosDeFaltasConsecutivas[2]);
    }
    get todasAsFaltas() {
        return this.selectedTipoDados.includes('Faltas sem reposição');
    }
    get dashboardReposicaoItemsFiltered() {
        const arr = [];
        // @ts-ignore
        _.forEach(this.dashboardReposicao, (item, index) => {
            // const ultimaAtividadeTime = new Date(
            //   // @ts-ignore
            //   this.BRToDate(item.ultima_atividade_presente)
            // ).getTime();
            // quantidade de alunos por dia da semana
            const diasSemana = _(item.cronograma_horario)
                .groupBy((i) => i.dia_semana)
                .value();
            const filtroDias = Object.keys(diasSemana);
            if (filtroDias.some((i) => this.propertyToShowDetails.includes(i))) {
                if (this.filtroStatus.includes(item.contrato_status)) {
                    if (this.todasAsFaltas) {
                        arr.push(item);
                        return;
                    }
                    if (item.quantidade_faltas_consecutivas >= 1 &&
                        item.quantidade_faltas_consecutivas <= 4 &&
                        this.de1a4) {
                        arr.push(item);
                    }
                    else if (item.quantidade_faltas_consecutivas >= 5 &&
                        item.quantidade_faltas_consecutivas <= 8 &&
                        this.de5a8) {
                        arr.push(item);
                    }
                    else if (item.quantidade_faltas_consecutivas > 8 && this.mais8) {
                        arr.push(item);
                    }
                }
            }
        });
        return arr;
    }
    get filteredItems() {
        let item = this.dashboardReposicaoItemsFiltered;
        item = item.filter((i) => {
            const turmaMatch = this.classRoom === 'Todos' || i.livro_nome === this.classRoom;
            const studentMatch = this.nameStudent === 'Todos' || i.aluno_nome === this.nameStudent;
            return turmaMatch && studentMatch;
        });
        return item;
    }
    get uniqueTurmas() {
        const unique = _.uniqBy(this.dashboardReposicaoItemsFiltered, 'livro_nome');
        return _.orderBy(unique.filter((item) => item.livro_nome), [], ['asc']);
    }
    get uniqueStudents() {
        const unique = _.uniqBy(this.dashboardReposicaoItemsFiltered, 'aluno_nome');
        return _.orderBy(unique.filter((item) => item.aluno_nome), [], ['asc']);
    }
    clearFilters() {
        this.search = '';
        this.nameStudent = 'Todos';
        this.classRoom = 'Todos';
    }
    get isDisabled() {
        return (this.search === '' &&
            this.nameStudent === 'Todos' &&
            this.classRoom === 'Todos');
    }
    openFirstStep(item) {
        this.firstStepObject = item;
        const form = this.$refs.showFirstStep;
        form.show();
    }
    get firstStep() {
        return [
            {
                value: `["Faltas consecutivas"]`,
                label: 'Faltas consecutivas sem reposição',
                description: 'Exibe os alunos que possuem falta em sequência. É prioritário agendar as reposições para esses alunos primeiro, pois eles acumulam múltiplas faltas, o que dificulta o agendamento posterior.',
                chipColor: 'redmain',
                chipText: 'Prioridade',
                count: _.sumBy(
                // @ts-ignore
                this.dashboardReposicao, (item) => item.quantidade_faltas_consecutivas > 0 &&
                    this.filtroStatus.includes(item.contrato_status)),
            },
            {
                value: `["Faltas sem reposição"]`,
                label: 'Faltas sem reposição',
                description: 'Exibe todos os alunos que possuem faltas sendo elas consecutivas ou não. Após agendar todas reposições para os alunos com faltas consecutivas, você pode agora agendar aula para esses outros alunos.',
                count: _.filter(this.dashboardReposicao, (item) => this.filtroStatus.includes(item.contrato_status)).length,
            },
        ];
    }
    get secondStep() {
        return [
            {
                label: '1 a 4 faltas consecutivas',
                count: this.aluno_1_a_4_faltas,
            },
            {
                label: '5 a 8 faltas consecutivas',
                count: this.aluno_5_a_8_faltas,
            },
            {
                label: 'Mais de 8 faltas consecutivas',
                count: this.aluno_mais_8_faltas,
            },
        ];
    }
    async showAluno(item) {
        this.selectedAluno = item;
        this.aluno = await alunoStore.getAluno(parseInt(this.selectedAluno.aluno_id, 0));
        this.showMarcarReposicao = true;
    }
    async updateAluno(novoAluno) {
        this.selectedAluno = novoAluno;
    }
    async carregaDados() {
        if (!this.panelOpen) {
            this.loading = true;
            // @ts-ignore
            await dashboardStore.getDashboardReposicao();
            this.loading = false;
            this.panelOpen = true;
        }
        else {
            this.panelOpen = false;
        }
    }
    handleBack() {
        this.showMarcarReposicao = false;
    }
    async mounted() {
        await this.carregaDados();
        this.items = await reposicaoStore.getNewReposicoesAlunos();
        this.items.forEach((item) => {
            item.quantidade = item.counts.reduce((total, count) => total + count, 0);
        });
    }
    handleAllReposicoes() {
        this.$router.push('/main/CadernosCadernodereposicao/reposicao');
    }
};
__decorate([
    Watch('propertyToShowDetails', { deep: true })
], Reposicao.prototype, "onChangePropertyToShowDetails", null);
Reposicao = __decorate([
    Component({
        components: {
            EntidadeLinkComponent,
            OpenNewTabComponent,
            TextExpandableComponent,
            ButtonComponent,
            ShowDialog,
            PrimeiraEtapaPopup,
            AlunaListReposicao,
        },
    })
], Reposicao);
export default Reposicao;
